<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Employee Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],
      title: "Employees",
      items: [
        {
          text: "View",
        },
        {
          text: "Employee",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      excelFields: {},
      fields: [
        {
          key: "Action",
          sortable: false,
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "role",
          sortable: true,
        },
        {
          key: "emailID",
          sortable: true,
        },
        {
          key: "phoneNO",
          sortable: true,
        },
        {
          key: "created",
          sortable: true,
        },
      ],
      path: "",
      editRight: "",
      deleteRight: "",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.readCloudkitchEmployees();
    this.checkUserRights();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    checkUserRights() {
      this.path = this.$route.path;
      this.axios.post(this.$loggedRole+"/checkUserRights", {
        empID: this.$storageData.profile.pid,
        empTypeID: this.$storageData.profile.empTypeID,
        path: this.path,
      })
      .then((result) => {
        this.editRight = result.data.data[0].isEdit;
        this.deleteRight = result.data.data[0].isDelete;
      });
    },
    deleteEmployee(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you really want to delete this employee?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!"
      }).then((result)=> {
        if (result.isConfirmed) {
          this.axios
              .post(this.$loggedRole+
                  "/delete-cloudkitch-employee",
                  { id: id },
              )
              .then((result) => {
                this.$swal({
                  text: result.data.message,
                  icon: "success",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000
                }).then(()=>{
                  this.readCloudkitchEmployees();
                });
              })
              .catch(error => {
                this.loading = false;
                this.$swal({
                  text: error.response.data.message,
                  icon: "error",
                });
              });
        }
      });


    },
    readCloudkitchEmployees() {
      this.axios.get(this.$loggedRole+"/viewAllCloudKitchAdmins").then((response) => {
        //Then injecting the result to datatable parameters.
        this.excelFields = {};
        this.tableData = response.data.data;
        //console.log((response));
        this.fields.forEach((element) => {
          // console.log(element.key);
          var key = element.key;
          // var tempObj = {key: key};
          this.excelFields["" + key + ""] = key;
          // console.log(tempObj);
          // tempExcelFields = {tempExcelFields, tempObj};
        });
      });
    },
  },
  middleware: "authentication",
};
</script>





<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom: 15px" v-if="this.$storageData.login_type == 1">
                <div role="group" class="btn-group">
                  <download-excel
                    type="button"
                    class="btn btn-themeBrown"
                    :data="tableData"
                    worksheet="Employee"
                    name="Employee.xls"
                  >
                    Excel
                  </download-excel>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      style="margin-left: 5px; margin-right: 5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                striped
                hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(Action)="data">
                  <router-link
                      v-if="$can('edit cloudkitch staff')"
                    :to="{
                      name: 'cloudkitchEmployeeForm',
                      params: { id: data.item.empID, type: 'edit' },
                    }"
                  >
                    <button type="button" class="btn btn-outline-warning btn-sm">
                      <i
                          class="uil uil-edit-alt"
                          title="Edit"
                          style="font-size: 19px"
                      ></i>

                    </button>
                  </router-link>
                  &nbsp;
                  <button type="button" v-if="$can('delete cloudkitch staff')" class="btn btn-outline-danger btn-sm" @click="deleteEmployee(data.item.empID)">
                    <i
                        class="uil uil-trash"
                        title="Delete"
                        style="font-size: 19px"
                    ></i>
                  </button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.my-class {
  max-width: 10px !important;
}
</style>